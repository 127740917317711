import React from "react";
import Seo from "../components/Seo";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import sej from "../images/sej.png"

// import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

// import JoinBeta from "../components/JoinBeta";
// import SignupForm from "../components/SignupForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const title = "Press - PlagiaShield";
const description = "Stay up to date on the latest company news.";

const IndexPage = () => {
  // const isLarge = useMediaQuery("(min-width: 48rem)");

  return (
    <React.Fragment>
      <div className="plagia-checker-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Press</h1>
        <p className="lead mb-4">Stay up to date on the latest company news.</p>
      </div>

      {/* <Container>
        <h2 className="text-center mb-4">Press Releases</h2>
        <div>
          <p>
            <a></a>PlagiaShield helps online businesses protect themselves from plagiarism.
          </p>
        </div>
      </Container> */}
      <Container>
        <AsSeenOn />
      </Container>
      <Container>
        <h2 className="text-center mb-4">Press Kit</h2>
        <div className="text-center">
          <Button
            href="https://drive.google.com/file/d/1f9e1nPyAb-xJgDkCDObNS12fhXlG0QrI/view?usp=sharing"
            rel="noopener noreferrer"
            target="_blank"
          >
            Download the press kit
          </Button>
        </div>
        <p>
          {" "}
          The press kit contains the following elements:
          <ul>
            <li>logos</li>
            <li>screenshots (1270x760px)</li>
            <li>photo of the founder</li>
            {/* <li>PDF with "About PlagiaShield" and "The Team" sections</li> */}
          </ul>
        </p>
      </Container>
      <Container>
        <h2 className="text-center mb-4">About PlagiaShield</h2>
        {/* <h2 className="text-center mb-4">Get results in minutes</h2> */}
        <div>
          <p>
            PlagiaShield is a plagiarism checker for SEO (Search Engine Optimization) professionals.
            <br />
            <br />
            The SaaS (software as a service) tool helps them make their existing content remain
            unique. It helps them achieve better rankings in the search results of Google and Bing.
            It monitors the customers written content like blog posts and finds potential thieves on
            the web. It then helps the SEO professional get rid of all pages inappropriately using
            their content.
          </p>
        </div>
      </Container>
      <Container>
        <h2 className="text-center mb-4">The team</h2>
        {/* <h2 className="text-center mb-4">Get results in minutes</h2> */}
        <div>
          {/* <h3>Founder: Jérémy MAUBOUSSIN</h3> */}
          <strong>Founder: Jérémy MAUBOUSSIN</strong>
          <p>
            Jérémy MAUBOUSSIN is a French entrepreneur and marketing technologist.
            <br />
            Jérémy is a food engineer by training. After 7 years as a product manager in the food,
            the retail and the pharmaceutical industry, he moved to the technical side of marketing.
            He became Chief Data Officer in a international market research company.
            <br />
            He started building and marketing PlagiaShield in March 2019, right after helping SEO
            friends protect their website from plagiarism.
          </p>
          <p>
            Contact: +33(0)632041231 or{" "}
            <a
              href="https://plagiashield.user.com/calendar/meeting/jeremy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              book a meeting
            </a>
          </p>
        </div>
      </Container>

      <Seo manualTitle={title} manualDescription={description} manualSlug="/press" />
    </React.Fragment>
  );
};

const sejQuote = <span>Automating the process of scanning the web for plagiarized content and identifying who reprinted it gives you an additional three options:
  <ul>
    <li>Ask for remuneration.</li>
    <li>Demand a backlink.</li>
    <li>Submit a DCMA to Google.</li>
  </ul>
</span>

const AsSeenOn = () => (
  <Row className="asseenon">
    <div >
      <div className="text-center">
        <h2 className="text-center mb-4">Featured In</h2>
        <a target="_blank"
          rel="noopener noreferrer" href="https://www.searchenginejournal.com/saas-blogs-plagiarism">
          <img className="" src={sej} alt="" width="150" />
        </a>
      </div >
      <div className="media-body m-3">
        <blockquote className="blockquote border-0 p-0">
          <p ><FontAwesomeIcon icon={faQuoteLeft} /> {sejQuote}</p>
          <footer className="blockquote-footer">Rebekah Dunne, writer for the
            <cite title="Source Title"> Search Engine Journal</cite>
          </footer>
        </blockquote>
      </div>
    </div>

  </Row>
)

export default IndexPage;
